<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Assessing Certificate Resources</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <ul>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+1+Getting+started.mov" target="_blank">
                VLE 1 Getting Started
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+2+Add+and+Delete+Evidence.mov" target="_blank">
                VLE 2 Add and Delete Evidence
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+3+Add+and+Delete+Coursework.mov" target="_blank">
                VLE 3 Add and Delete Coursework
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+4+Resources.mov" target="_blank">
                VLE 4 Resources
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/VLE+5+Messages.mov" target="_blank">
                VLE 5 Messages
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/assessing_knowledge_handbook_taqa_dec_2024.pdf" target="_blank">
                Assessing Knowledge TAQA Handbook
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_2_assessing_award_individual_learning_plan_ilp_general_dec_2024.docx" target="_blank">
                Assessing Certificate Individual Learning Plan (ILP)
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_7_learner_overview_dec_2024.doc" target="_blank">
                Learner overview
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/pocket_8_taqa_witness_statement_for_assessors_1_dec_2024.doc" target="_blank">
                TAQA Witness statement for assessors
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_1_the_assessment_process_my_roles_and_responsibilities_dec_2024.docx" target="_blank">
                Worksheet 1 The Assessment process -  My roles and responsibilities
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_2_functions_and_types_of_assessment_dec_2024.doc" target="_blank">
                Worksheet 2 Functions and types of Assessment
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_3_assessment_methods_dec_2024.doc" target="_blank">
                Worksheet 3 Assessment methods
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_4_risks_involved_in_assessment.doc" target="_blank">
                Worksheet 4 Risks involved in assessment
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_5_factors_to_consider_when_planning_dec_2024.doc" target="_blank">
                Worksheet 5 Factors to consider when planning
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/worksheet_6_quality_assurance_and_disputes_dec_2024.doc" target="_blank">
                Worksheet 6 Quality assurance and disputes
              </a>
            </li>
            <li>
              <a href="https://taqa-dkb-training.co.uk/resources/worksheet_7_legislation_and_regulatory_ requirements_dec_2024.doc" target="_blank">
                Worksheet 7  Legislation and regulatory  requirements
              </a>
            </li>
            <li>
              <a href="https://jade-solutions.s3.eu-west-1.amazonaws.com/simple_vle/resources/TAQA_L3_Assessing_slides.pdf" target="_blank">
                TAQA L3 Assessing slides
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssessingCertificateResources',
};
</script>
